.t {
  transform: none;
}
[policy] {
  padding-top: 8.75rem;
  padding-right: 0rem;
  padding-bottom: 8.75rem;
  padding-left: 0rem;
  padding-left: 1.125rem;
  padding-right: 1.125rem;
}
[policy] h1 {
  padding-bottom: 4.5rem;
  font-size: 1.75rem;
  line-height: 0.09375rem;
  letter-spacing: -0.015625rem;
  line-height: 1.5;
  font-weight: 700;
  text-align: center;
  color: #1e1e1e;
}
[policy] p {
  font-size: 1rem;
  line-height: 0.12125rem;
  letter-spacing: -0.015625rem;
  line-height: 1.94;
  color: #555;
}
[policy] p s {
  font-weight: 700;
  color: #333;
}
@media (min-width: 768px) {
  [policy] {
    padding-top: 8.25rem;
    padding-right: 0rem;
    padding-bottom: 11.25rem;
    padding-left: 0rem;
  }
  [policy] h1 {
    padding-bottom: 5.25rem;
  }
  [policy] p {
    width: 30rem;
    margin-left: auto;
    margin-right: auto;
  }
}
@media (min-width: 1024px) {
  [policy] {
    padding-top: 9rem;
    padding-right: 0rem;
    padding-bottom: 12.5rem;
    padding-left: 0rem;
  }
  [policy] h1 {
    padding-bottom: 6rem;
    font-size: 2.25rem;
    line-height: 0.09375rem;
    letter-spacing: -0.015625rem;
    line-height: 1.5;
  }
  [policy] p {
    width: 40.625rem;
    font-size: 1.125rem;
    line-height: 0.118125rem;
    letter-spacing: -0.015625rem;
    line-height: 1.89;
  }
}
@media (min-width: 1440px) {
  [policy] {
    padding-top: 10.375rem;
    padding-right: 0rem;
    padding-bottom: 17.5rem;
    padding-left: 0rem;
  }
  [policy] h1 {
    padding-bottom: 7.5rem;
    font-size: 2.75rem;
    line-height: 0.09375rem;
    letter-spacing: -0.015625rem;
    line-height: 1.5;
  }
}
@media (min-width: 1600px) {
  [policy] {
    padding-top: 12.75rem;
    padding-right: 0rem;
    padding-bottom: 18.75rem;
    padding-left: 0rem;
  }
  [policy] h1 {
    padding-bottom: 8.25rem;
    font-size: 3rem;
    line-height: 0.09375rem;
    letter-spacing: -0.015625rem;
    line-height: 1.5;
  }
}
